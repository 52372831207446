<template>
    <div class="sets main-page">
        <!--        <router-view></router-view>-->

        <div class="row bear-crumbs grey lighten-4"></div>
        <section class="sets">
            <Loader v-if="loading" />
            <div v-else class="row center text-center">
                <div class="page-title">
                    <h3>Ягодные наборы</h3>
                </div>

                <div class="search">
                    <div class="input-field">
                        <input id="search" v-model="filterText" type="text" />
                        <label class="active" for="search">Поиск</label>
                    </div>
                </div>

                <div
                    v-for="product in filteredProducts"
                    class="product-item col s12 m6 l4 xl3"
                    :class="{ productInCart: product.id === productItem.id }"
                >
                    <div class="product-card col s12 m6 l4 xl3">
                        <div class="img-container" @click="toProduct(product)">
                            <img
                                :src="product.mainImageSrc"
                                class="img responsive-img"
                                alt=""
                            />
                        </div>

                        <div class="actions">
                            <div class="card-info">
                                <span class="title">{{ product.title }}</span>
                                <span class="price"
                                    >{{ product.price | currency }}
                                </span>
                            </div>

                            <button
                                v-if="
                                    cart.find(
                                        (val) => val.productId === product.id
                                    )
                                "
                                class="btn btn-buy btn-flat row waves-effect waves-light disabled"
                                @click.prevent="addProductToCart(product)"
                            >
                                <i class="material-icons white-text"
                                    >check_circle_outline</i
                                >
                            </button>

                            <button
                                v-else
                                class="btn btn-buy btn-flat row waves-effect waves-light"
                                @click.prevent="addProductToCart(product)"
                            >
                                <i class="material-icons white-text"
                                    >shopping_cart</i
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data: () => ({
        name: "sets",
        cart: [],
        allProducts: [],
        filterText: "",
        categories: [],
        select: null,
        current: null,
        productItem: {
            id: "",
        },
    }),

    mounted() {
        this.cart = this.$store.getters.getCart;
        this.allProducts = this.$store.getters.getProducts;
    },
    computed: {
        products() {
            return this.allProducts.filter(
                (el) => el.parentCategory.Path === this.$route.path
            );
        },

        filteredProducts() {
            return this.products.filter((element) =>
                element.title.toLowerCase().match(this.filterText.toLowerCase())
            );
        },

        loading() {
            return this.$store.getters.loading;
        },
    },

    methods: {
        toProduct(p) {
            window.scroll({
                top: 100,
                left: 0,
            });
            this.$router.push(`/product/${p.id}`);
        },
        addProductToCart(product) {
            this.productItem.id = product.id;

            this.cartItem = {
                productId: product.id,
                productImage: product.mainImageSrc,
                productTitle: product.title,
                amount: 1,
                price: product.price,
                sum: 1 * product.price,
            };
            this.$addToCart();
            this.$store.commit("addProductToCart", this.cartItem);
        },
    },
};
</script>
